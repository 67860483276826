@import '~@andes/tabs/base';
@import '~@andes/tabs/lib/styles/mobile';

@import './index';

.#{$app-prefix}-tabs {
  .andes-tabs__wrapper {
    background-color: $andes-white;
  }

  .andes-tabs__swipeable-content {
    margin: 0 -8px;
  }

  .#{$app-prefix}-tabs-component {
    margin-bottom: $andes-spacing-16;
  }

  .ui-recommendations-list__items-wrapper--default {
    .poly-card--list.poly-card--mobile {
      --poly-image-size: 144px;
    }

    .poly-card--list.poly-card--mobile .poly-card__portada {
      --poly-image-size: 144px;
    }
  }
}

.#{$app-prefix}-tabs-animation-left-to-first
  .andes-tabs__swipeable-content-scroll {
  animation: tabs-slide-left-to-first 0.6s;
}

.#{$app-prefix}-tabs-animation-left .andes-tabs__swipeable-content-scroll {
  animation: tabs-slide-left 0.6s;
}

.#{$app-prefix}-tabs-animation-right .andes-tabs__swipeable-content-scroll {
  animation: tabs-slide-right 0.6s;
}

@keyframes tabs-slide-left-to-first {
  from {
    transform: translateX(calc(-100% + 1.5em));
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes tabs-slide-left {
  from {
    transform: translateX(calc(-200% + 3em));
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes tabs-slide-right {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(calc(-100% + 1.5em));
  }
}
