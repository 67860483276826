@import '~@recommendations-fe/carousel-free/src/styles/andes-bundle.mobile';
@import '../recommendations/carousel-free/index';

.#{$app-prefix}-carousel-featured {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  padding: $andes-spacing-12 0 $andes-spacing-16 $andes-spacing-8;

  .poly-card {
    border: 1px solid #0000001a;
    max-width: 148px;
    border-radius: $border-radius-6;
  }

  .andes-card--flat {
    box-shadow: none;
  }
}
