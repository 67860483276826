.#{$social-prefix} {
  .#{$social-prefix}_container {
    background-color: $andes-white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .aff-social-header__container {
      width: 100%;
      padding-bottom: 24px;
    }
  }

  .#{$social-prefix}_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: $andes-spacing-32;

    &:has(.ui-recommendations-carousel-wrapper-ref) {
      padding-top: $andes-spacing-24;
    }

    .#{$social-prefix}_title {
      padding: $andes-spacing-12;
    }
  }

  .carousel-featured {
    padding: $andes-spacing-12 0 $andes-spacing-24 $andes-spacing-8;
  }
}
