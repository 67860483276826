.#{$social-prefix} {
  .#{$social-prefix}-fee {
    background-color: $andes-gray-040-solid;
    padding: 0 $andes-spacing-16 $andes-spacing-16;

    .affiliate-container {
      padding: 0;
    }
  }
}
