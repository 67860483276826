@import '~@recommendations-fe/carousel-free/src/styles/recommendations-fe-carousel-free.mobile';

.#{$app-prefix}-carousel-free {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .ui-recommendations-title {
    font-size: $font-size-24;
  }
}

.carousel-free__polycard {
  width: 220px;
}
