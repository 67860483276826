// Base
@import '../../../styles/common';

// Variables
$social-prefix: '#{$app-prefix}-social-mobile';

// Andes
@import '~@andes/button/index';
@import '~@andes/card/index';
@import '~@andes/common/lib/styles/visually-hidden';
@import '~@andes/snackbar/index';
@import '~@andes/typography/index';

// Empty State & Technical Error
@import '../../../components/empty-state/mobile';
@import '../../../components/technical-error/index';

// Recommendations
@import '~@recommendations-fe/list/src/styles/recommendations-fe-list.mobile';

// Eshop
@import 'eshops-components-library/src/components/ProfileHeader/lib/desktop';
@import 'eshops-components-library/src/components/ProfileHeader/lib/mobile';
@import 'eshops-components-library/src/components/AffiliateFeeInfo/lib/mobile';

// Polycard
@import '~@polycard/phrase/build';
@import '~@polycard/card/build';

// Internal
@import '../ui-components/profile/profile.mobile';
@import '../ui-components/denounce/mobile';
@import '../ui-components/header/mobile';
@import '../../../components/card-featured/mobile';
@import '../../../components/carousel-featured/index';
@import '../../../components/tabs/mobile';

.#{$social-prefix} {
  .#{$social-prefix}-recos {
    margin: $andes-spacing-16 10px 10px 10px;
  }

  .#{$social-prefix}-subtitle {
    background-color: $andes-white;
    padding: $andes-spacing-12 $andes-spacing-12 0;
    color: var(--text-andes-text-color-primary, rgb(0 0 0 / 90%));
    font-family: $font-family-primary;
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    line-height: $line-height-base;
  }

  .#{$social-prefix}-adn {
    background-color: $andes-white;
    padding-top: $andes-spacing-8;
  }

  .#{$social-prefix}-line {
    border-bottom: 1px solid $andes-gray-070-solid;
  }

  .no-border {
    .ui-recommendations-list__items-wrapper--default
      .ui-recommendations-card--horizontal {
      border-bottom: none;
    }
  }

  .#{$app-prefix}-card-featured {
    border-top: none;
    border-bottom: none;
  }

  .sold-quantity-component {
    margin-bottom: 8px;
  }

  .poly-card--mobile .poly-component__title {
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  }

  .#{$app-prefix}-tabs {
    background-color: $andes-bg-secondary;
  }
}
